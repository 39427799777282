<template>
  <v-dialog :value="isOpen" max-width="430px" @click:outside="$emit('clickOutside')" @keydown.esc="$emit('clickOutside')">
    <v-card class="pa-2">
      <v-card-title>
        <p class="ma-0" style="font-size: 1.25rem;">{{ translations.managingTimeCardFor }}:</p>
        <p class="ma-0 pl-1" style="font-size: 1.25rem;">
          {{ employeeName }}
        </p>
      </v-card-title>
      <v-card-text>
        <!-- InertClockInItems -->
        <div v-for="(item, index) in newClockInRequest.inertClockInItems" :key="index">
          <inert-clock-in-item
            :inertClockInItem="item"
            :index="index"
            :sectorOptions="sector"
            :activityOptions="activity"
            :isLast="isLast(index)"
            @fetch-more="value => $emit('fetch-more', value)"
            @refetch="value => $emit('refetch', value)"
            @add-item="addEmptyInertClockInItem()"
            @remove-item="value => removeInertClockInItem(value)"
          >
          </inert-clock-in-item>
        </div>

        <!-- Lunch Break -->
        <div v-if="possibleQuestions.includes(4)" class="report-row mb-4">
          <label>{{ translations.howLongWasYourLunchBreak }}</label>
          <v-select class="mt-2 pa-0 mb-2" v-model="newClockInRequest.lunchTime" :items="lunchBreakDropdown" 
                    item-text="text" item-value="value" dense outlined height="35px" hide-details 
                    style="width: 200px">
          </v-select>
        </div>

        <!-- clock in/out times -->
        <div class="report-row">
          <label>{{ translations.type }} </label>
          <div class="d-flex align-center">
            <v-text-field disabled :value="translations.in" class="mr-2" style="max-width: 100px; font-size: 14px" outlined height="35px" hide-details></v-text-field>
            <v-text-field v-model="newClockInRequest.clockInTimestamp" v-mask="'##:##'" :rules="[rules.isTimeFormat]" style="max-width: 100px" outlined hide-details></v-text-field>
          </div>
        </div>
        <div class="mt-2 mb-4 report-row">
          <div class="d-flex align-center">
            <v-text-field disabled :value="translations.out" class="mr-2" style="max-width: 100px; font-size: 14px" outlined height="35px" hide-details></v-text-field>
            <v-text-field v-model="newClockInRequest.clockOutTimestamp" v-mask="'##:##'" :rules="[rules.isTimeFormat]" style="max-width: 100px" outlined hide-details></v-text-field>
          </div>
        </div>

        <div v-if="showNextDayOutOnDialog" class="mb-2">
          <v-alert dense text type="warning" class="ma-0">{{ translations.shiftWillEndNextDay }}</v-alert>
        </div>

        <div class="mt-10 d-flex justify-end">
          <v-btn depressed color="main" :loading="newClockInLoader" :dark="!disableSubmitNewClockInClockOutButton" :disabled="disableSubmitNewClockInClockOutButton" @click="submitNewClockInClockOut">{{ translations.confirm }}</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>


<script>
import { translations } from '@/utils/common'
import InertClockInItem from '@/components/InertClockInItem.vue'
import { getInertSectorKeyCode, getLunchTimes, getInertLunchTimesDefault } from '@/utils/api/config'

export default {
  name: 'NewInertClockInClockOutDialog',
  components: { 
    InertClockInItem 
  },

  props: {
    employeeName: {
      type: String
    },
    isOpen: {
      type: Boolean
    },
    sector: {
      type: Object,
      default: () => {
      }
    },
    newClockInLoader: {
      type: Boolean
    },
    activity: {
      type: Object,
      default: () => {}
    },
    possibleQuestions: {
      type: Array,
      default: () => []
    },
  },

  created() {
    this.newClockInRequest = this.getEmptyInertClockIn()
    this.lunchBreakDropdown = getLunchTimes()
  },

  data: () => ({
    newClockInRequest: null,
    rules: {
      isTimeFormat: value => {
        const pattern = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
        return pattern.test(value)
      },
      isHour: value => {
        const pattern = /^(?!0$)\d+(?:[,.][05])?$/
        return pattern.test(value)
      }
    },
    lunchBreakDropdown: []
  }),

  computed: {
    translations: () => translations,

    showNextDayOutOnDialog() {
      const clockInTime = Number(this.newClockInRequest.clockInTimeData.HH)
      const clockOutTime = Number(this.newClockInRequest.clockOutTimeData.HH)

      if (clockOutTime === 0) return false
      return clockInTime > clockOutTime
    },

    disableSubmitNewClockInClockOutButton() {
      if (!this.rules.isTimeFormat(this.newClockInRequest.clockInTimestamp) || (this.newClockInRequest.clockOutTimestamp && !this.rules.isTimeFormat(this.newClockInRequest.clockOutTimestamp))) return true

      if (this.newClockInRequest.lunchTime === null || this.newClockInRequest.lunchTime === 'undefined') return true

      const inertClockInItemsMissingFields = this.newClockInRequest.inertClockInItems.some(i => {
        return !i.sector 
          || (!i.hours || i.hours <= 0)
          || (getInertSectorKeyCode().includes(i.sector.keyCode) && !i.activityNotes) 
          || (i.sector && !getInertSectorKeyCode().includes(i.sector.keyCode) && !i.activity)
      })

      if (inertClockInItemsMissingFields) return true

      return false
    }
  },

  methods: {
    projectFilterInputHandler(value) {
      if (!value) this.newClockInRequest.sector = null

      this.$emit('set-project-list-filter', { value, property: 'rowDialogSectorOptions' })
    },

    clearSearchHandler() {
      this.newClockInRequest.sector = null
      this.$emit('set-project-list-filter', { value: '', property: 'rowDialogSectorOptions' })
    },

    getProjectFilterValue() {
      return this.newClockInRequest?.sector?.keyCode ? this.newClockInRequest?.sector?.keyCode : ''
    },

    selectSectorHandler(sector) {
      this.newClockInRequest.sector = sector
      this.$emit('set-project-list-filter', { value: '', property: 'rowDialogSectorOptions' })
    },

    onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        return this.$emit('fetch-more-projects')
      }
    },

    submitNewClockInClockOut() {
      this.$emit('onSubmit', {
        newClockInRequest: this.newClockInRequest
      })
      this.newClockInRequest = this.getEmptyInertClockIn()
    },

    getEmptyInertClockIn() {
      return {
        employee: null,
        clockInTimeData: {
          HH: '00',
          mm: '00'
        },
        clockOutTimeData: {
          HH: '00',
          mm: '00'
        },
        referenceDate: null,
        lunchTime: getInertLunchTimesDefault(),
        inertClockInItems: [{
          sector: null,
          activity: null,
          hours: 0,
          activityNotes: null
        }]
      }
    },

    isLast(index) {
      return index === this.newClockInRequest.inertClockInItems.length - 1
    },

    addEmptyInertClockInItem() {
      this.sector.params.filter = null
      this.activity.params.filter = null
      this.$emit('refetch-all')
      this.newClockInRequest.inertClockInItems.push({
        sector: null,
        activity: null,
        hours: 0,
        activityNotes: null
      })
    },

    removeInertClockInItem(index) {
      this.newClockInRequest.inertClockInItems.splice(index, 1)
    }
  }
}
</script>
